import { SiteInfo } from "../model/models";

export function getUrlVars(): any {
    var vars = [], max = 0, hash: any = "", array = "";
    var url = window.location.search;

    //?を取り除くため、1から始める。複数のクエリ文字列に対応するため、&で区切る
    hash = url.slice(1).split('&');
    max = hash.length;
    for (var i = 0; i < max; i++) {
        array = hash[i].split('=');    //keyと値に分割。
        vars.push(array[0]);    //末尾にクエリ文字列のkeyを挿入。
        vars[array[0]] = array[1];    //先ほど確保したkeyに、値を代入。
    }
    return vars;
}

export function getPageType(): PageType {
    if (window.location.pathname.toLowerCase().indexOf("/phone/product/") != -1) {
        return PageType.SpProduct;
    } else if (window.location.pathname.toLowerCase().indexOf("/product/") != -1) {
        return PageType.PcProduct;
    }

    if (window.location.pathname.toLowerCase().indexOf("/phone/page/") != -1) {
        return PageType.SpFree;
    } else if (window.location.pathname.toLowerCase().indexOf("/page/") != -1) {
        return PageType.PcFree;
    }

    if (window.location.pathname.toLowerCase().indexOf("/phone/") != -1) {
        return PageType.SpOther;
    }
    return PageType.PcOther;
}

export enum PageType {
    PcProduct,
    SpProduct,
    PcFree,
    SpFree,
    PcOther,
    SpOther,
}

export function parseProduct(idx, itemDiv) {
    const productName = jQuery(itemDiv).find(".goods_name").text();
    const unitPrice = jQuery(itemDiv).find(".cart_price").find(".price").text().replace("円", "").split(",").join("");
    const selectNum = jQuery(itemDiv).find(".cart_quantity").val();
    const qentity = "[" + idx + "].";
    return qentity + "productName=" + encodeURIComponent(productName) + "&" + qentity + "unitPrice=" + unitPrice + "&" + qentity + "selectNum=" + selectNum;
}

export function toEstimate(siteInfoId:number) {
    const formInnnerHtml = jQuery("#register").html();
    const results = new Array();
    let idx = 0;
    jQuery(".cart_data_box")
        .each(function () {
            results.push(parseProduct(idx, this));
            idx++;
        });

    const query = results.join("&");
    const url = `https://www.workup-system.com/Estimate/Print/?siteId=${siteInfoId}&${query}`;
    window.open(url);
}

export function shippingDateSelectionReplace() {
    const $select = $('.shipping_date_selection');
    $select.attr('autocomplete', 'off');
    // <option>要素をループしてテキストを変更
    $select.find('option').each(function () {
        const $option = $(this);
        if ($option.text() === '指定なし') {
            $option.text('指定なし(最短)');
        }
    });
}